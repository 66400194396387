<template>
	<section class="section section--larger">
		<div class="container">
			<h1 class="title">Contactați-ne</h1>
			<h2 class="subtitle">Informații de contact</h2>

			<div class="columns is-multiline is-centered mb-6">
				<!-- Contact Information -->
				
				<!-- Email -->
				<div class="column is-6-desktop is-12-tablet">
					<div class="card contact-card">
						<div class="card-content">
							<a href="mailto:contact@aloeveraproducts.ro">
								<ion-icon name="mail-outline"></ion-icon>
								contact@aloeveraproducts.ro
							</a>
						</div>
					</div>
				</div>
				
				<!-- WhatsApp -->
				<div class="column is-6-desktop is-12-tablet">
					<div class="card contact-card">
						<div class="card-content">
							<a href="https://wa.me/qr/PHUQ3LVN5PQAP1" target="_blank">
								<ion-icon name="logo-whatsapp"></ion-icon>
								WhatsApp
							</a>
						</div>
					</div>
				</div>

				<!-- Phone -->
				<div class="column is-6-desktop is-12-tablet">
					<div class="card contact-card">
						<div class="card-content">
							<a href="tel:+40744775567">
								<ion-icon name="call-outline"></ion-icon>
								+40 744 775 567
							</a>
						</div>
					</div>
				</div>
				<!-- Phone -->
				<div class="column is--desktop is-6-tablet">
					<div class="card contact-card">
						<div class="card-content">
							<a href="tel:+40752033315">
								<ion-icon name="call-outline"></ion-icon>
								+40 752 033 315
							</a>
						</div>
					</div>
				</div>

				<!-- Facebook -->
				<div class="column is-6-desktop is-12-tablet">
					<div class="card contact-card">
						<div class="card-content">
							<a href="https://www.facebook.com/vera.mester" target="_blank">
								<ion-icon name="logo-facebook"></ion-icon>
								vera.mester
							</a>

						</div>
					</div>
				</div>
			</div>

			<!-- Contact Form -->
			<!--<div class="column is-8">
				<h2 class="subtitle">Send us a message</h2>
				<form>
					<div class="field">
						<label class="label">Name</label>
						<div class="control">
							<input class="input" type="text" placeholder="Your Name">
						</div>
					</div>
					<div class="field">
						<label class="label">Email</label>
						<div class="control">
							<input class="input" type="email" placeholder="Your Email">
						</div>
					</div>
					<div class="field">
						<label class="label">Message</label>
						<div class="control">
							<textarea class="textarea" placeholder="Your Message"></textarea>
						</div>
					</div>
					<div class="field">
						<div class="control">
							<button class="button is-primary">Send</button>
						</div>
					</div>
				</form>
			</div>-->

			<!-- <component-certificates
				:certificates="certificates"
			></component-certificates> -->

			<testimonial-carousel />

			<owner-carousel />
		</div>
	</section>
</template>

<script>
	import axios from 'axios';
	import { mapActions, mapState } from 'vuex';
	import { email, required } from 'vuelidate/lib/validators';

	import OwnerCarousel from '@/components/TheOwnerCarousel.vue';
	import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	// import ComponentCertificates from '@/components/ComponentCertificates.vue';

	export default {
		metaInfo() {
			return {
				title: `Aloe Vera Products - Contact`
			};
		},
		components: {
			OwnerCarousel,
			TestimonialCarousel,
			// ComponentCertificates,
		},
		data() {
			return {
				email: '',
			};
		},
		validations: {
			email: {
				required,
				email,
			},
		},
		computed: {
			...mapState('general', [
			'certificates'
			]),
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('article', ['article']),
			articleId() {
				let route = this.$route.path;
				return route.slice(route.lastIndexOf('-') + 1);
			},
		},
		created() {
			this.getArticle(this.articleId);
		},
		mounted() {
			// Trigger prerendering
			if (typeof window !== 'undefined') {
				window.dispatchEvent(new Event('prerender-trigger'));
			}
  		},
		methods: {
			...mapActions('article', ['getArticle']),
			submit() {
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					axios
						.post(
							`${process.env.VUE_APP_STRAPI_DATABASE_URL}/subscribes`,
							{
								mail: this.email,
							}
						)
						.then((data) => {
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emails`,
								{
									mail: data.data.mail,
								}
							);
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailsd`,
								{ ...data }
							);
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'submited',
									msg:
										'Mulțumim pentru interes. Am notat datele tale și te vom contacta în cel mai scurt timp.',
								}
							);
						})
						.catch(() => {
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'error',
									msg:
										'Am întâmpinat o eroare. Datele nu au fost trimise.',
								}
							);
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
/* Style the contact information list */

.contact-card {
	.card-content {
		font-size: 20px;
	}
	ion-icon {
		font-size: 28px;
		float: left;
		margin: 3px 5px 0 5px;
	}
}

.article {
		&__image {
			padding-top: 56.25%;
			overflow: hidden;
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__meta {
			span {
				font-weight: $medium;
			}
		}
		form {
			margin-top: 2rem;
			width: 600px;
			max-width: 100%;
			.field {
				&--error {
					input,
					textarea,
					option {
						border-color: $red;
						&:focus {
							box-shadow: 0 0 0 3px rgba($red, 0.25);
						}
					}
				}
			}
			label {
				background-color: $primary;
				color: $white;
				display: block;
				font-size: 1rem;
				font-weight: $bold;
				padding: 0.75rem 1rem;
				border-width: 1px 1px 0 1px;
				border-color: $gray;
				border-style: solid;
				border-top-right-radius: $round-corners;
				border-top-left-radius: $round-corners;
				&.terms {
					color: $black;
				}
			}
			input,
			textarea,
			select {
				box-sizing: border-box;
				background: $white;
				border: 1px solid $gray;
				border-radius: $round-corners;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				color: $primary;
				display: block;
				font-size: 1rem;
				padding: 0.75rem 1rem;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 100%;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
			.error {
				color: $red;
				font-size: 0.875rem;
				font-weight: $medium;
			}
		}
	}
</style>
